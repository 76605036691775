import type { SubmitButtonTranslationObj } from "../../translation-keys/submit-button-text-keys.js";

const DownloadSubmitButtonText = "Téléchargez vos ressources dès maintenant!";
const SubmitButtonMapText: SubmitButtonTranslationObj = {
"document-Submit-Button": DownloadSubmitButtonText,
"document-landing-page-Submit-Button": DownloadSubmitButtonText,
"post-event-assets-Submit-Button": "Get Instant Access", //need info
"ppc-Submit-Button": DownloadSubmitButtonText,
"ppc-contact-Submit-Button": "Get Some Answers",
"case-study-Submit-Button": "Télécharger l'étude de cas",
"video-Submit-Button": DownloadSubmitButtonText,
"video-landing-page-Submit-Button": "Submit and Play", //need info
"video-gated-Submit-Button": "Submit and Play", //need info
"contact-Submit-Button": "Envoyer",
"qx-roi-Submit-Button": "Programmez votre évaluation du ROI",
"mx-roi-Submit-Button": "Programmez votre évaluation du ROI",
"spark-Submit-Button": "Contacter un expert Spark",
"pricing-Submit-Button": "Obtenir le prix",
"subscription-Submit-Button": "S'abonner",
"partner-Submit-Button": "Submit", //need info
"demo-request-Submit-Button": "Schedule Your Demo", //need info
"tradeshow-giveaway-Submit-Button": "Submit", //need info
"event-registration-Submit-Button": "Register Now",
"subprocessor-update-notification-Submit-Button": "Submit", //need info
"digital-maturity-assessment-Submit-Button": "Access My Full Report",
"qx-demo-video-Submit-Button": "Watch the Demo!",
"ppc-video-Submit-Button": "Watch the Video"
};

export default SubmitButtonMapText;
